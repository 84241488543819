import React from 'react';
import { useTransition, useSpring } from 'react-spring';
import { useMeasure } from '@components/common/helpers';
import { Container, Input, Label, ErrorMsg } from './styles';

type Props = {
	title: string,
	name: string,
	type?: string,
	as?: string,
	errors: Array<string>,
}

const FormItem: React.FC<Props> = props => {
	const transitions = useTransition(props.errors && props.errors[props.name] ? props.errors[props.name] : [], null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});
	const [contentRef, { height: contentH }] = useMeasure();
	const springProps = useSpring({
		from: { height: 'auto'},
		to: { height: contentH },
		config: { tensiton: 300, friction: 20 }
	});

	return (
		<Container style={ springProps }>
			<div { ...contentRef }>
				<Label>{ props.title }</Label>
				<Input
					name={ props.name }
					type={ props.type }
					as={ props.as }
				/>
				{ transitions.map(({ item, key, props: transProps }) => {
					return (
						<ErrorMsg children={ item } key={ key } style={ transProps }/>
					)
				})}
			</div>
		</Container>
	)
}

FormItem.defaultProps = {
	type: "text",
}

export default FormItem;

