import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import media from 'styled-media-query';
import { color, breakpoint } from '@src/settings';

export const Container = styled(animated.div)`
	width: 100%;
	& + &{
		margin-top: 2.5rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		& + &{
			margin-top: 1.8rem;
		}
	`}
`;

export const Input = styled.input`
	font-size: 1.6rem;
	height: 4rem;
	background-color: #F5F5F5;
	margin-top: 1.5rem;
	width: 100%;
	outline: none !important;
	padding: 0.5rem;
	border: none;
	${ props => props.as == "textarea" && `
		min-height: 15rem;
	`}
`;

export const Label = styled.label`
	font-size: 1.6rem;
	color: ${color.blue};
	display: block;
	${ props => props.isRequired && `
	&:after {
		content: "必須";
		display: inline-block;
		color: white;
		padding: 4px 6px;
		background-color: ${color.blue};
		margin-left: 1.5rem;
		font-size: 0.9rem;
		line-height: 1.6rem;
	}
	`}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		${ Input } + &{
			margin-top: 1.5rem;
		}
	`}
`;

export const ErrorMsg = styled(animated.p)`
	display: block;
	color: #F00;
	font-size: 1.4rem;
	line-height: 1.3;
	& + & {
		margin-top: 0.5rem;
	}
	${ Input } + &{
		margin-top: 1rem;
	}
`;

