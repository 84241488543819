import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import BtnBase from '@components/common/btn';
import { breakpoint } from '@src/settings';

export const Cont = styled.form`
	flex: auto 1 1;
	width: 100%;
`;
export const ReCaptchaCont = styled.div`
	margin-top: 2.5rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		& > div > div{
			display: flex;
			justify-content: center;
		}
	`}
`

export const Btn = styled(BtnBase)`
	display: block;
	width: 22rem;
	margin-top: 4rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-top: 1.8rem;
	`}
`;
