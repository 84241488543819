import React,{ useRef, useState } from 'react';
import { useTransition, useSpring, animated, config } from 'react-spring';
import { graphql } from 'gatsby';
import { color } from "@src/settings"
import { useMeasure } from '@components/common/helpers';

import Hero from '@components/common/hero';
import Hero__mds from '@components/contact/svg/hero__mds.svg';
import Form from '@components/contact/form';
import Thanks from '@components/contact/thanks';
import Loading from '@components/contact/loading';
import { PageCont, Container, Wrapper, Content, PageIntro, Info } from '@components/contact/styles';

const ContactPage = ({ data }) => {

	/* ----------------------------------------------------
	 * smoothScroll
	 * ---------------------------------------------------*/

	const [, setY] = useSpring(() => ({ y: 0 }));
	let isStopped = false;
	const onWheel = () => {
		isStopped = true;
		window.removeEventListener('wheel', onWheel)
	}
	const scrollToTop = () => {
		window.addEventListener('wheel', onWheel);
		setY({
			y: 0,
			reset: true,
			from: { y: window.scrollY },
			onRest: () => {
				isStopped = false;
				window.removeEventListener('wheel', onWheel)
			},
			onFrame: props => {
				if(!isStopped){
					window.scroll(0,props.y)
				}
			}
		})
	}
	/* smoothScroll
	 * ---------------------------------------------------*/

	const [ formState, setFormState ] = useState("input");

	const wrapperRef = useRef();
	const contentRef = useRef();

	const timer = (num) => new Promise(resolve => {
		setTimeout(() => {resolve()}, num)
	})

	const [springProps, setSpring] = useSpring(() => ({ height: 'auto' }));

	const transitions = useTransition( formState, null, {
		from: { position: 'absolute', opacity: 0 },
		enter: item => async (next, cancel) => {
			scrollToTop();
			await next({ position: 'relative', config: { duration: 1 }});
			await timer(0,() => {
				setSpring({ height: `${ contentRef.current.getBoundingClientRect().height }px` });
			})
			await timer(2000);
			await next({ opacity: 1 })
			await timer(0,() => { wrapperRef.current.style.height = "auto"})
		},
		leave: item => async (next, cancel) => {
			await timer(0,() => {
				setSpring({ height: `${ wrapperRef.current.getBoundingClientRect().height }px` });
			})
			scrollToTop();
			await next({ position: 'absolute', opacity: 0 })
		},
		config: (item, state) => {
			switch(state){
				case "enter": return { tension: 220, friction: 10 }
				case "leave": return { tension: 400, friction: 20 }
			}
		}
	})

	return (
		<PageCont baseBgColor={ color.paleGray }>
			<main>
				<Hero
					img={ data["heroImg"] }
					title={<Hero__mds />}
					subTitle="お問い合わせ"
				/>
				<Container>
					<Wrapper ref={ wrapperRef } style={ springProps }>
						<div ref={ contentRef }>

						{ transitions.map(({ item, key, props: transProps }) => {
							switch(item){
								case "input":
									return (
										<animated.div style={ transProps } key={ key }>
											<PageIntro
												title={<>どんなことでもまずはお気軽にお尋ねください。</>}
												subtitle={<>お問い合わせは以下のメールフォームよりご連絡ください。<br/>必要事項をご記入の上、「入力内容のご確認」ボタンを押してください。</>}
											/>
											<Content>
												<Info>
													<h4>LSF TECHNOLOGY</h4>
													<p>Lot 5747, Kidamai Industrial Park,Bukit Angkat,<br/>43000 Kajang, Selangor Darul Ehsan, Malaysia.</p>
													<h5>TEL</h5>
													<p>+60 3 8736 6030</p>
													<h5>FAX</h5>
													<p>+60 3 8738 6030</p>
												</Info>
												<Form
													setFormState={ setFormState }
													items={[
															{
																title: "お名前",
																name: "name",
																rules: "required",
																isRequired: true,
															},
															{
																title: "会社名",
																name: "company",
																isRequired: false,
															},
															{
																title: "メールアドレス",
																name: "email",
																rules: "required|email",
																isRequired: true,
															},
															{
																title: "メールアドレス（確認）",
																name: "email_confirm",
																rules: "required|same:email",
																isRequired: true,
															},
															{
																title: "ご用件",
																name: "inquiry",
																rules: "required",
																as: "textarea",
																isRequired: true,
															}
														]}
													btnLabel="送信"
												/>
											</Content>
										</animated.div>
									);
									break;
								case "sending":
									return (
										<animated.div style={ transProps } key={ key }>
											<Loading />
										</animated.div>
									);
									break;
								case "done":
									return (
										<animated.div style={ transProps } key={ key }>
											<Thanks
												title="お問い合わせありがとうございます"
												desc={<>送信が完了しました。<br />ご記入いただいたメールアドレスに確認用メールを送信いたしましたのでご確認ください。<br />担当者より数日以内にご連絡いたします。</>}
											/>
										</animated.div>
									);
									break;
							}
						})}
						</div>
					</Wrapper>
				</Container>
			</main>
		</PageCont>
	)
}


export const query= graphql`
	query ContactImgs{
		
		heroImg: file( relativePath: { eq: "contact/hero-img_200414.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

	},
`;

export default ContactPage
