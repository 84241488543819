import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import * as loadingAnim from './animation.json';

const lottieOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingAnim.default,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
}

const Loading = props => (
	<Cont>
		<Lottie
			options={ lottieOptions }
			width={ 80 }
			height={ 80 }
		/>
	</Cont>
)

const Cont = styled.div`
	padding: 5rem 0;
`;

export default Loading;
