import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ReCaptcha from 'react-google-recaptcha';
import axios from 'axios';
import Validator from 'validatorjs';
import formSerialize from 'form-serialize';
import { createBreakpoint } from 'react-use';

import FormItem from './form-item';
import { Cont, ReCaptchaCont, Btn } from './styles';
import { breakpoint } from '@src/settings';

const useBreakpoint = createBreakpoint({ sp: 0, tb: breakpoint.sp });

const Form = props => {
	const bp = useBreakpoint();	
	const [ formError, setError ] = useState(null)
	const [ isVerified, setVerify ] = useState(false)
	const formRef = useRef(null);

	const messages = {
		required: ':attributeは必須項目です',
		email: '入力内容がメールアドレスとして正しくありません',
		same: '入力内容が一致しません'
	}

	//validatorに渡す設定を作成
	const inputNameList = {}
	const rules = {}
	props.items.map((item) => {
		inputNameList[item.name] = item.title;
		if(item.rules != undefined) rules[item.name] = item.rules;
	})
	
	const submit = () => {
		const formInputs = formSerialize(formRef.current, { hash: true })
		const _validate = (data:any) => {
		const validateInfo = props.langEn ? new Validator( data, rules ) : new Validator( data, rules, messages );
			validateInfo.setAttributeNames(inputNameList);
			return validateInfo;
		}

		const validation = _validate(formInputs)
		if( validation.fails() ){
			setError(validation.errors.all())
			return
		}
		setError(null);
		isVerified && send(validation);
	}

	const send  = async (validation) => {
		const params = {inputs:{}}
		Object.keys( validation.input ).map( key => {
			//確認用メールアドレスと""で渡ってくるcaptchaのデータは送信しない
			if(key == "email_confirm" || key == "g-recaptcha-response"){
				return;
			}
			params.inputs[key] = { label: inputNameList[key], value: validation.input[key] };
		})
		params.lang = props.lang;
		props.setFormState('sending');
		const res = await axios.post('https://lsftechnology.com/contact-api/send.php',JSON.stringify(params));

		//表示確認用
		//const res = await dammyPost();

		if(res.data == "send mail Done"){
			props.setFormState('done');
			setTimeout(() => props.setFormState('input'),3000);
		}
	}
	const dammyPost = () => new Promise( resolve => {
		setTimeout(() => { resolve({ data: "send mail Done" }) }, 5000);
	});
	return (

		<Cont ref={ formRef }>
			{ props.items.map( (item, index) => (
				<FormItem
					title={ item.title }
					name={ item.name }
					as={ item.as }
					isRequired={ item.isRequired }
					errors={ formError }
					key={ index }
				/>
			))}

			<ReCaptchaCont>
				<ReCaptcha
					sitekey="6Lc4VfwUAAAAAOOPkDmqkdl9H5T-Mtk1Y6zhLszx"
					onChange={ () => setVerify(true) }
					onExpired={ () => setVerify(false) }
					size={ bp == "sp" ? "compact" : "normal" }
				/>
			</ReCaptchaCont>

			<Btn onClick={ () => submit() }>{ props.btnLabel }</Btn>

		</Cont>
	);

}

const mapStateToProps = (state,props) => ({
	lang: state.langReducer.language,
})

export default connect(mapStateToProps)(Form)
