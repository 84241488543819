import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { animated } from 'react-spring';
import PageIntroBase from '@components/common/page-intro';
import Layout from '@components/common/layout';
import { mixin, color, breakpoint, size } from '@src/settings';

export const PageCont = styled(Layout)`
	padding-bottom: 11rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding-bottom: ${ size.sectionGap_sp }px;
	`}
`;

export const Container = styled.div`
	margin-top: -4rem;
	${ mixin.baseWidthSet }
	position: relative;
	z-index: 1;
	padding: 6rem 5rem;
	background-color: #FFF;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding: 2.5rem 2.5rem 4rem;
		margin-top: 4rem;
	`}
`;

export const Wrapper = styled(animated.div)`
	width: 100%;
	top: 0;
	left: 0;
`;

export const Content = styled.div`
	margin: auto;
	display:flex;
	justify-content: space-between;
`

export const PageIntro = styled(PageIntroBase)`
	padding: 0;
	margin-bottom: 4rem;
	padding-bottom: 4rem;
	border-bottom: 1px solid #DEDEDE;
	& > .container{
		width: 100%;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding-bottom: 2rem;
		margin-bottom: 2rem;
	`}
`

export const Info = styled.div`
	flex: 22rem 0 0;
	margin-right: 3rem;
	h4 {
		font-size: 1.6rem;
		line-height: 2.1rem;
		color: #22569B;
		margin-bottom: 2.9rem;
	}
	h5 {
		font-size: 1.3rem;
		margin-top: 2.9rem;
		color: #6E7D91;
	}
	p {
		font-size: 1.4rem;
		line-height: 1.6;
		text-align: left;
		margin-top: 0.8rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: none;
	`}
`;

