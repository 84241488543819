import React from 'react';
import styled from 'styled-components';

const Cont = styled.div`
	margin: auto;
	max-width: 60rem;
	h2{
		font-size: 1.8rem;
		line-height: 1.3;
		margin-bottom: 2.5rem;
	}

	p{
		font-size: 1.5rem;
		line-height: 1.8;
	}
`;

const Thanks= (props) => (
	<Cont className={props.className}>
		<h2>{props.title}</h2>
		<p>{props.desc}</p>
	</Cont>
);

//export
export default Thanks;

